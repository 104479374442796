<script setup>
import {computed, onMounted, onUnmounted, ref, defineProps} from 'vue'
import {useRoute, useRouter} from 'vue-router'

const props = defineProps({
  path: {
    type: String,
  },
  duration: {
    type: Number,
    default: .7,
  },
  color: {
    type: String,
    default: '#1a1a1a',
  },
})

const x = ref(0)
const y = ref(0)


// const show = ref(false)
// function updateShow() {
//   show.value = window.location.pathname === props.pathname
// }
// updateShow()
const route = useRoute()
const router = useRouter()
const show = computed(() => route.path.startsWith(props.path))

const diameter = ref(0)
function updateDiameter() {
  diameter.value = 2.5 * Math.max(window.innerWidth, window.innerHeight)
}
onMounted(() => {
  window.addEventListener('resize', updateDiameter)
})
onUnmounted(() => {
  window.removeEventListener('resize', updateDiameter)
})
updateDiameter()

function toggle(e) {
  x.value = e.clientX
  y.value = e.clientY
  if (show.value) {
    router.replace('/')
  } else {
    router.push(props.path)
  }
}
</script>

<template>
  <slot name="activator" :toggle="toggle"></slot>
  <div
    class="ripple"
    :class="{'visible': show}"
    :style="{
      'width': show ? diameter + 'px' : '0',
      'height': show ? diameter + 'px' : '0',
      'top': y + 'px',
      'left': x + 'px',
      'transition-duration': duration + 's',
      'background': color,
    }"
  />
  <div class="content" :class="{'visible': show}" :style="{
    'transform': 'scale(' +  (show ? 1 : 0) + ')',
    'transform-origin': x + 'px ' + y + 'px',
    'transition-duration': duration + 's',
  }">
    <slot :toggle="toggle"></slot>
  </div>
</template>

<style lang="scss" scoped>

.ripple {
  overflow: hidden;
  width: 0;
  height: 0;
  border-radius: 50%;
  position: fixed;
  transition-property: width, height;
  transition-timing-function: linear;
  transform: translateY(-50%) translateX(-50%);
  z-index: 100;
  transition-delay: .1s;
  &.visible {
    transition-delay: 0s;
  }
}

.content {
  position: absolute;
  //overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  color: #e1e1e1;
  z-index: 200;
  transition: none;
  transition-property: transform;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  &.visible {
    transition-delay: .1s;
  }
}
</style>
