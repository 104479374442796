<script setup>
import {defineProps, ref} from 'vue'

defineProps({
  password: String,
})

const videos = ref([{
  title: '',
  url: '/voest/24.mp4',
  playing: false,
}, {
  title: '',
  url: '/voest/26.mp4',
  playing: false,
}, {
  title: '',
  url: '/voest/28.mp4',
  playing: false,
}, {
  title: '',
  url: '/voest/29.mp4',
  playing: false,
}])

</script>

<template>
  <div class="section">
    <h2>voestalpine Image Kampagne (Social Media Assets)</h2>
    <p>
      voestalpine hat sich im November 2023 nahbar und menschlich gezeigt. Mit der Kampagne
      #OurFavoriteThings wurde in den Mittelpunkt gerückt, was zählt: Menschen.
    </p>

    <p>
      Ich habe als Copywriterin an der Erstellung der Social Media Assets mitgearbeitet
      und diese mit Konzepten und Texten vorangebracht. Die Dreiklänge ziehen sich durch
      die gesamte Kampagne und nehmen immer auf ein Überthema Bezug. Themen waren unter
      anderem Nachhaltigkeit, Ausbildung und Frauen in Tech.
    </p>

    <p>
      Im Rahmen der Social Media Adaption wurden Bewegtbild-Sequenzen sowie statische Postings erstellt.
    </p>

      <div class="video-container">
          <div class="video" v-for="video in videos" :key="video.url">
            <vue-plyr
              :options="{
                loadSprite: false,
                iconUrl: '/plyr.svg',
              }"
              @play="video.playing = true"
              @pause="video.playing = false"
            >
              <video controls crossorigin playsinline>
                <source :src="'/' + password + video.url" type="video/mp4" />
              </video>
            </vue-plyr>
            <div v-if="!video.playing" class="video-title">{{ video.title }}</div>
          </div>
        </div>


    </div>

</template>

<style scoped lang="scss">

</style>