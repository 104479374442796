<script setup>
import {defineProps, onMounted, onUnmounted, ref} from 'vue'

const props = defineProps({
  size: {
    type: Number,
    default: 8,
  },
  followerSize: {
    type: Number,
    default: 48,
  },
  followerSpeed: {
    type: Number,
    default: .1,
  },
})

const x = ref(0)
const y = ref(0)
const caughtUp = ref(false)
const followerX = ref(0)
const followerY = ref(0)

function updatePosition(e) {
  x.value = e.x
  y.value = e.y
}

function updateFollower() {
  followerX.value = followerX.value + props.followerSpeed * (x.value - followerX.value)
  followerY.value = followerY.value + props.followerSpeed * (y.value - followerY.value)
  caughtUp.value = Math.round(followerX.value) === Math.round(x.value) && Math.round(followerY.value) === Math.round(y.value)
}

onMounted(() => {
  window.addEventListener('mousemove', updatePosition);
  window.setInterval(updateFollower, 16)
})
onUnmounted(() => {
  window.removeEventListener('mousemove', updatePosition)
})
</script>

<template>
  <div class="cursor" :style="{
    'width': (caughtUp ? followerSize : size) + 'px',
    'height': (caughtUp ? followerSize : size) + 'px',
    'transition-duration': (caughtUp ? .7 : .1) + 's',
    'top': y + 'px',
    'left': x + 'px',
  }" />

  <div class="follower" :style="{
    'transition-duration': (caughtUp ? .7 : .5) + 's',
    'opacity': caughtUp ? 0 : 1,
    'width': (caughtUp ? followerSize * .8 : followerSize) + 'px',
    'height': (caughtUp ? followerSize * .8 : followerSize) + 'px',
    'top': followerY + 'px',
    'left': followerX + 'px',
  }" />
</template>

<style scoped>
.cursor {
  color: #1a1a1a;
  background-color: #eaeaea;
  position: fixed;
  pointer-events: none;
  border-radius: 50%;
  mix-blend-mode: difference;
  transform: translateY(-50%) translateX(-50%);
  transition-property: width, height;
  transition-timing-function: ease-in-out;
  z-index: 1000;
}

.follower {
  color: #1a1a1a;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  border-radius: 50%;
  mix-blend-mode: difference;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 1px solid #eaeaea;
  z-index: 998;
  transform: translateY(-50%) translateX(-50%);
  transition-property: opacity, width, height;
  transition-timing-function: ease-in-out;
}
</style>